// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bj_kz";
export var caseStudyBackgroundContainer = "bj_kr";
export var caseStudyBackgroundContainer__ship = "bj_ks";
export var caseStudyBackground__bgColor = "bj_kp";
export var caseStudyBackground__lineColor = "bj_kq";
export var caseStudyHead__imageWrapper = "bj_kn";
export var caseStudyProjectsSection = "bj_kB";
export var caseStudyQuote__bgLight = "bj_kx";
export var caseStudyQuote__bgRing = "bj_kw";
export var caseStudySolution__ring = "bj_kt";
export var caseStudySolution__ringTwo = "bj_kv";
export var caseStudyTech = "bj_ky";
export var caseStudy__bgDark = "bj_km";
export var caseStudy__bgLight = "bj_kl";